import React, { Component } from 'react';
import jQuery from 'jquery';
import axios from 'axios';

import StepList from './Steps/StepList1';
import StepList2 from './Steps/StepList2';

// import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-select2-wrapper/css/select2.css';

class App extends Component {
  constructor(props) {
    super(props)

    let state = JSON.parse(window.sessionStorage.getItem('state'));
    if(state) {
      state.step = 4;
        this.state = state;
        window.sessionStorage.removeItem('state');    
    } else {
      this.state = {
          step: 1,
          return_id: 1,
          resort: null,
          offerCount: 0,
          form: {
            resort_name: '',
            resort: '',
            bedrooms: '',
            bathrooms: '',
            max_occupancy: '',
            usage_type: '',
            ownership_type: '',
            week: '',
            points_allocation: '',
            season: '',
            usage_frequency: '',
            owner_name: '',
            email: '',
            phone_number: '',
          }
        }
    }
    jQuery('body').addClass('wmtw-form');
    this.nextStep = this.nextStep.bind(this)
  }

  nextStep() {
    this.setState({
      step: this.state.step + 1
    })
  }

  clearResort() {
    this.setState({
        resort: null,
        offerCount: 0
    })
  }

  fetchResort(id) {
    let those = this;
    axios.get(window.wmtw.asyncUrl+'/wp-admin/admin-ajax.php?action='+(window.wmtw.autosuggest ? 'getResort' : 'getResortId')+'&id='+id)
    .then(function (response) {
      those.setState({
        resort: response.data.data
      });
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  stepList1() {
    if(this.state.step === 1 || this.state.step === 2) {
      return (<StepList App={this} />);
    }
  }

  stepList2() {
    if(this.state.step === 3 || this.state.step === 4) {
      return (<StepList2 App={this} />);
    }
  }





  render() {
    return (
      <div>
        { this.stepList1() }
        { this.stepList2() }
      </div>
    );
  }
}

export default App;
