import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// import './style.css';

// window.wmtw = {
// 	siteUrl: 'http://wbast.vinmulti.local',
// 	sitePath: 'http://wbast.vinmulti.local/wp-content/themes/we_buy_and_sell_timeshare',
//     asyncUrl: 'http://smtn.local/',
// 	imagePath: "https://images.sellmytimesharenow.com/data/images/",
// 	autosuggestUrl: 'http://smtn.local/wp-content/themes/smtn',
// 	autosuggest: true,
//     settings: {
//         "id": "wmtw",
//         "hero-image": "http://wbast.vinmulti.local/wp-content/uploads/sites/4/2018/10/resort-pool.png",
//         "form-id": false,
//         "form-configuration": "none",
//         "query": "",
//         "phone_cta": "Call For Your Offer Now! ",
//         "phone": "855-609-4438",
//         "show_steps": true,
//         "snippet": "<a href=\"https://www.bbb.org/central-florida/business-reviews/timeshare-resale-and-rental-marketing/v-i-network-in-orlando-fl-134612180#sealclick\" target=\"_blank\" rel=\"noreferrer noopener\"><br />\n                            <img alt=\"Click for the BBB Business Review of this Timeshare Resale and Rental Marketing in Orlando FL\" src=\"https://seal-centralflorida.bbb.org/seals/blue-seal-120-61-whitetxt-vinetworkinc-134612180.png\" /></a>",
//         "image_snippet": "http://wbast.vinmulti.local/wp-content/uploads/sites/4/2018/10/yahoo-featured-img.png"
//     },
//     step1: {
//         "title": "Get an Offer on Your Timeshare in 3 Simple Steps",
//         "subtitle": "",
//         "subheading": "Fill out the form to get your offer!",
//         "content-1": "cpt",
//         "content-1-repeater": [{"title":"d","text":"c"},{"title":"b","text":"a"}],
//         "content-1-ext": "We Buy and Sell Timeshares can provide you with an offer for your timeshare TODAY. There's no obligation to learn your options. Get your offer now",
//         "content-2": "cpt",
//         "content-2-repeater": [{"title":"a","text":"b"},{"title":"c","text":"d"}],
//         "next": "Next Step!",
//         "privacy": "Privacy Guarantee: We will never sell your personal information as stated in our Privacy Policy, and you agree that we may send you email communication regarding the services described on this website pursuant to the Terms of Use."
//     },
//     step2: {
//         "subheading": "Step Not required! [next]Skip This Step and Get Your Offer[/next]",
//         "block-1": "Since 1999, we have provided resale solutions to timeshare owners. We can help you, too.",
//         "content-2": "cpt",
//         "content-2-repeater": [{"title":"a","text":"b"},{"title":"c","text":"d"}],
//         "previous": "Previous Step",
//         "next": "Next Step!"
//     },
//     step3: {
//         "title": "Your Timeshare Offer Details",
//         "form-title": "Receive Your Offer Now",
//         "button": "Get Your Offer Now!"
//     },
//     confirmation: {
//         "confirmation": "<h3>Thank You for Your Submission</h3>\n<p>Your ownership information has been received and is currently being reviewed by one of our timeshare resale specialists.</p>\n<h3>What Happens Now?</h3>\n<p>We are reviewing industry data (including current market values) related to your ownership. One of our specialists will contact you shortly with your personal timeshare offer. We offer a variety of services for all situations and ownerships, so please rest assured that we have a solution for you. For immediate service, please call WeBuyAndSellTimeshares.com directly at 855-609-4438.</p>\n<h3>How Do We Calculate Your Offer?</h3>\n<p>To determine the offer for your unique ownership and situation, we will review current market prices and sales offers through WeBuyandSellTimeshares.com and our partner websites. In addition, we review your situation (whether or not you still have a mortgage, as well as other factors) to provide you with a fair offer.</p>\n<p><strong>We will also consider the following ownership details:</strong></p>\n<ul>\n<li>Home Resort &amp; Location (if applicable)</li>\n<li>Ownership Type (deeded or right-to-use)</li>\n<li>Usage (annual, biennial, triennial, etc.)</li>\n<li>Week/Season (is the week in demand)</li>\n<li>Unit Type &amp; Size</li>\n<li>Vacation Ownership Brand (if applicable)</li>\n</ul>\n"
//     },
//     testimonials: [{
// 	    "title": "Joy &amp; Joanne O'brien, New York",
// 	    "text": "I would recommend WeBuyAndSellTimeshares.com to anyone. You were efficient and if I ever called, you always answered my questions honestly."
// 	}, {
// 	    "title": "Graham Millard, United Kingdom",
// 	    "text": "We have paid over $1000 on 2 occasions to two different companies - once organized on the phone and the other when we were in Florida itself. I have learnt that I will never really buy another time share, and we are really pleased with your service. Thank you for your help!"
// 	}, {
// 	    "title": "Kathy Burns, California",
// 	    "text": "You can trust WeBuyAndSellTimeshares.com because they are legitimate and do what's in your best interest. They are a very professional organization and I was impressed with their information and resources."
// 	}],
// 	faqs: [{
// 	    "title": "What will you do with my information?",
// 	    "text": "We Buy and Sell Timeshares will never sell or share your personal information. Please see our Privacy Policy."
// 	}, {
// 	    "title": "How do you determine my offer?",
// 	    "text": "Our team of experts review the details of what you own, including your brand, resort, week, season, whether or not you have points, and more to assist you in pricing your timeshare. Depending on your goals (if you want a quick sale, if you need to cover remaining mortgage expenses) we can help you decide how to price your ownership."
// 	}, {
// 	    "title": "What if I can't answer a question?",
// 	    "text": "We can still help you. And if you do not know every detail about your ownership, we may be able to assist you in finding the necessary information. For an initial market value survey, the more information you can give us the better, but we can provide you with pricing details regardless."
// 	}],
// };

ReactDOM.render(<App />, document.getElementById('wmtw'));
