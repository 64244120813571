import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import validator from 'validator';
import classNames  from 'classnames';
import axios from 'axios';
import jQuery from 'jquery';
import Inputmask from "inputmask";
import StepHeader from './StepHeader';
import DOMPurify from 'dompurify';
require('es6-shim');


class Step3 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      owner_name: this.props.App.state.form.owner_name,
      email: this.props.App.state.form.email,
      phone_number: this.props.App.state.form.phone_number,
      isLoading: false,
      phoneError: '',
    };

    this.setInputRef = this.setInputRef.bind(this);
    this.checkPhoneValue = this.checkPhoneValue.bind(this);
  }

  componentWillMount() {
    jQuery('body, html').addClass('step3')

    jQuery('html, body').animate({
      scrollTop: 0
    }, 1000);
  }

  componentWillUnmount() {
    jQuery('body, html').removeClass('step3')
  }

  componentDidMount() {
    var maskProps = {
      rightAlign: false,
      regex: String.raw`^[0-9\ \(\)\+\-\#]*$`,
      placeholder: '',
      clearMaskOnLostFocus: true,
    };
    var inputmask = new Inputmask(maskProps);
    inputmask.mask(this.input);
  }

  setInputRef(inputComponent) {
    this.input = ReactDOM.findDOMNode(inputComponent);
  }

  checkPhoneValue(inputComponent) {
    if(this.state.phone_number.replace(/\D/g,'').length < 10) {
      this.setState({
        phone_number: '',
        phoneError: 'Minimum phone number length is 10 characters'
      })
    }
  }

  firstNameOnChange = (event) => {
    this.setState({
      owner_name: event.target.value
    });
  };

  emailOnChange = (event) => {
    this.setState({
      email: event.target.value
    });
  };

  phoneNumberOnChange = (event) => {
    this.setState({
      phone_number: event.target.value,
      phoneError: ''
    });
  };

  nextStep(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if(!validator.isEmail(this.state.email) || !this.state.owner_name || !this.state.phone_number || this.state.isLoading) {
      return;
    }

    this.setState({
      isLoading: true
    });

    let those = this;

    this.props.App.setState({
      form: {
        resort_name: this.props.App.state.form.resort_name,
        resort: this.props.App.state.resort.SkyhookId,
        bedrooms: this.props.App.state.form.bedrooms,
        bathrooms: this.props.App.state.form.bathrooms,
        max_occupancy: this.props.App.state.form.max_occupancy,
        usage_type: this.props.App.state.form.usage_type,
        ownership_type: this.props.App.state.form.ownership_type,
        week: this.props.App.state.form.week,
        points_allocation: this.props.App.state.form.points_allocation,
        season: this.props.App.state.form.season,
        usage_frequency: this.props.App.state.form.usage_frequency,
        owner_name: this.state.owner_name,
        email: this.state.email,
        phone_number: this.state.phone_number,
      }
    }, function() {
      axios.post(window.wmtw.siteUrl+ '/wp-admin/admin-ajax.php?action=handlerWmtw', {
        data: {
          resort_name: those.props.App.state.form.resort_name,
          resort: those.props.App.state.resort.SkyhookId,
          bedrooms: those.props.App.state.form.bedrooms,
          bathrooms: those.props.App.state.form.bathrooms,
          max_occupancy: those.props.App.state.form.max_occupancy,
          usage_type: those.props.App.state.form.usage_type,
          ownership_type: those.props.App.state.form.ownership_type,
          week: those.props.App.state.form.week,
          points_allocation: those.props.App.state.form.points_allocation,
          season: those.props.App.state.form.season,
          usage_frequency: those.props.App.state.form.usage_frequency,
          owner_name: those.state.owner_name,
          email: those.state.email,
          phone_number: those.state.phone_number,
        },
        page_id: window.wmtw.pageId
      }, 'cors': true)
      .then(function (response) {

        those.setState({
          isLoading: false
        });

        those.props.App.setState({
          survey: response.data.survey,
        })

		window.sessionStorage.setItem("state", JSON.stringify(those.props.App.state));

		if(response.data.url) {
			window.location.href = response.data.url;
		} else {
		    those.props.App.setState({
	          step: 4,
	          return_id: response.data,
	        })
		}       



      })
      .catch(function (error) {
        those.setState({
          isLoading: false
        });
      });
    });
  }

  prevStep(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.props.App.setState({
      step: 2,
      form: {
        resort_name: this.props.App.state.form.resort_name,
        resort: this.props.App.state.form.resort,
        bedrooms: this.props.App.state.form.bedrooms,
        bathrooms: this.props.App.state.form.bathrooms,
        max_occupancy: this.props.App.state.form.max_occupancy,
        usage_type: this.props.App.state.form.usage_type,
        ownership_type: this.props.App.state.form.ownership_type,
        week: this.props.App.state.form.week,
        points_allocation: this.props.App.state.form.points_allocation,
        season: this.props.App.state.form.season,
        usage_frequency: this.props.App.state.form.usage_frequency,
        owner_name: this.state.owner_name,
        email: this.state.email,
        phone_number: this.state.phone_number,
      }
    });
  }

  title() {
    if(window.wmtw.step3['form-title'] !== undefined) {
      return window.wmtw.step3['form-title'];
    }

    return '';
  }

  button() {
    if(window.wmtw.step3.button !== undefined) {
      return window.wmtw.step3.button;
    }

    return '';
  }

  privacy() {
    if(window.wmtw.step1.privacy !== undefined) {
      return {__html: DOMPurify.sanitize(window.wmtw.step1.privacy)};
    }

    return '';
  }

  render() {
    const loadingText = this.state.isLoading ? ( <span className="fa fa-spinner fa-pulse fa-fw"></span> ) : ( <span>{ this.button() } <span className="fa fa-fw fa-chevron-circle-right"></span></span> );

    return (
      <div aria-labelledby="myModalLabel" className="modal" id="step3modal" role="dialog" tabIndex="-1">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <StepHeader App={ this.props.App }/>
                </div>
                <div className="modal-body form-fields">
                    <h4 className="modal-title text-center" id="myModalLabel">
                        { this.title() }
                    </h4>
                    <div className="row">
                        <form className="form">
                            <div className="form-group col-md-10 col-md-push-1">
                                <label className="sr-only" htmlFor="name">
                                    Name
                                </label>
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        <i className="far fa-user">
                                        </i>
                                    </span>
                                    <input className="form-control" id="name" placeholder="Name *" type="text" onChange={this.firstNameOnChange} value={this.state.owner_name}>
                                    </input>
                                </div>
                            </div>
                            <div className={ classNames({'form-group col-md-10 col-md-push-1': true, 'has-error': this.state.email !== '' && !validator.isEmail(this.state.email) }) }>
                                <label className="sr-only" htmlFor="email">
                                    Email
                                </label>
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        <i className="far fa-envelope">
                                        </i>
                                    </span>
                                    <input className="form-control" id="email" placeholder="Email *" type="email" onChange={this.emailOnChange} value={this.state.email}>
                                    </input>
                                </div>
                            </div>
                            <div className={ classNames({'form-group col-md-10 col-md-push-1': true, 'has-error': this.state.phoneError }) }>
                                <label className="sr-only" htmlFor="telephone">
                                    Telephone
                                </label>
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        <i className="fas fa-phone">
                                        </i>
                                    </span>
                                    <input className="form-control" id="telephone" placeholder="Telephone *" type="tel" onChange={this.phoneNumberOnChange} value={this.state.phone_number} ref={this.setInputRef} onBlur={this.checkPhoneValue}>
                                    </input>
                                </div>
                            </div>
                            <div className="form-group col-md-10 col-md-push-1">
                                <button className="btn btn-lg btn-block btn-primary" type="button" onClick={ this.nextStep.bind(this) } disabled={ !validator.isEmail(this.state.email) || !this.state.owner_name || this.state.phone_number.replace(/\D/g,'').length < 10 || this.state.isLoading }>
                                  { loadingText }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <i className="fas fa-lock fa-2x fa-pull-left">
                    </i>
                    <small className="text-muted text-center" dangerouslySetInnerHTML={this.privacy()}>
                    </small>
                </div>
            </div>
        </div>
    </div>
    );
  }

}

export default Step3;
