import React, { Component } from 'react';

import Step3 from './Step3';
import Step4 from './Step4';

class StepList2 extends Component {

  step3() {
    if(this.props.App.state.step === 3) {
      return (<Step3 App={ this.props.App }/>);
    }

    return;
  }

  step4() {
    if(this.props.App.state.step === 4) {
      return (<Step4 App={ this.props.App } offline={ false } />);
    } else if(this.props.App.state.step === 3) {
      return (<Step4 App={ this.props.App } offline={ true } />);
    }

    return;
  }

  title_1() {
    if(window.title_1 !== undefined) {
      return window.title_1;
    }

    return '';
  }

  title_2() {
    if(window.title_2 !== undefined) {
      return window.title_2;
    }

    return '';
  }

  title_3() {
    if(window.title_3 !== undefined) {
      return window.title_3;
    }

    return '';
  }

  title_4() {
    if(window.title_4 !== undefined) {
      return window.title_4;
    }

    return '';
  }

  renderStepsTitle() {
    if(this.props.App.state.step === 3) {
      return (<div className="title">Your { this.props.App.state.resort.Name } Market Value </div>);
    }

    return (
        <div className="title">Find Out Your Timeshare’s Value </div>
      //<div className="subtitle">in 3 Simple Steps</div>
    );

  }

  renderStepsSubtitle() {
    if(this.props.App.state.step === 4) {
      return '';
    }

    return (
        <div className="subtitle">in 3 Simple Steps</div>
    );
  }

  background() {
    if(window.wmtw.settings['hero-image'] !== undefined) {
      return 'url('+window.wmtw.settings['hero-image']+')';
    }

    return '';
  }

  title() {
    if(window.wmtw.step3.title !== undefined) {
      return window.wmtw.step3.title;
    }

    return '';
  }

  render() {
    return (
      <div>
        <div className="main index3" style={{ backgroundImage: this.background() }}>
          <div className="container">
            <div className="row">
              <div id="lead-form" className="form-header col-md-12">
                <h1 className="text-center">{ this.title() }</h1>
              </div>
            </div>
          </div>
        </div>
        { this.step3() }
        { this.step4() }
      </div>
    );
  }


  render2(){
    return (
      <div>
        <div className="find_out_your_timeshares_value_2">
          <div className="container">
            { this.renderStepsTitle() }
            { this.renderStepsSubtitle() }
            <div className="steps">
              <div className="numbers">
                <div className="number finished">
                  <span>1</span>
                </div>
                <div className="space"><span></span></div>
                <div className="number finished">
                  <span>2</span>
                </div>
                <div className="space"><span></span></div>
                <div className="number finished">
                  <span>3</span>
                </div>
                <div className="space"><span></span></div>
                <div className={"number special " + (this.props.App.state.step === 4 ? 'active' : '') }>
                  <span>$</span>
                </div>
              </div>
              <div className="texts">
                <div className="text"><span>{ this.title_1() }</span></div>
                <div className="space"></div>
                <div className="text"><span>{ this.title_2() }</span></div>
                <div className="space"></div>
                <div className="text"><span>{ this.title_3() }</span></div>
                <div className="space"></div>
                <div className={"special " + (this.props.App.state.step === 4 ? 'active' : '') }><span>{ this.title_4() }</span></div>
              </div>
            </div>
          </div>
        </div>
        { this.step3() }
        { this.step4() }
      </div>
    );
    }
}

export default StepList2;