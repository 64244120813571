import React, { Component } from 'react';
import Select2 from 'react-select2-wrapper';
import Inputmask from "inputmask";
import ReactDOM from 'react-dom';

class Step2 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      usage_type: this.props.App.state.form.usage_type,
      ownership_type: this.props.App.state.form.ownership_type,
      week: this.props.App.state.form.week,
      points_allocation: this.props.App.state.form.points_allocation,
      season: this.props.App.state.form.season,
      usage_frequency: this.props.App.state.form.usage_frequency,
      offerCount: 0,
      weekData: [
        { id: 'Fixed', text: 'Fixed' },
        { id: 'Float', text: 'Float' },
      ],
      usageData: [
        { id: 'Annual', text: 'Annual' },
        { id: 'Biennial-odd year', text: 'Biennial-odd year' },
        { id: 'Biennial-even year', text: 'Biennial-even year' },
        { id: 'Triennial', text: 'Triennial' },
      ],
      seasonData: [
        { id: 'White', text: 'White' },
        { id: 'Red', text: 'Red' },
        { id: 'Blue', text: 'Blue' },
        { id: 'Yellow', text: 'Yellow' },
        { id: 'Green', text: 'Green' },
        { id: 'N/A', text: 'N/A' },
        { id: 'Flex', text: 'Flex' },
        { id: 'Flex Gold', text: 'Flex Gold' },
        { id: 'Flex Silver', text: 'Flex Silver' },
        { id: 'Flex Platinum', text: 'Flex Platinum' },
      ]
    };

    this.setInputRef = this.setInputRef.bind(this);
  }

  componentWillMount() {
  }

  componentDidMount() {
    var maskProps = {
      clearMaskOnLostFocus: false,
      rightAlign: false,
      regex: String.raw`^[0-9\ \(\)\+\-\#]*$`,
      placeholder: ''
    };
    var inputmask = new Inputmask(maskProps);
    inputmask.mask(this.input);
  }

  setInputRef(inputComponent) {
    this.input = ReactDOM.findDOMNode(inputComponent);
  }

  weekOnChange = (event) => {
    this.setState({
      week: event.target.value
    });
  };

  pointsAllocationOnChange = (event) => {
    this.setState({
      points_allocation: event.target.value
    });
  };

  seasonOnChange = (event) => {
    this.setState({
      season: event.target.value
    });
  };

  usageFrequencyOnChange = (event) => {
    this.setState({
      usage_frequency: event.target.value
    });
  };


  nextStep(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.props.App.setState({
      step: 3,
      form: {
        resort_name: this.props.App.state.form.resort_name,
        resort: this.props.App.state.form.resort,
        bedrooms: this.props.App.state.form.bedrooms,
        bathrooms: this.props.App.state.form.bathrooms,
        max_occupancy: this.props.App.state.form.max_occupancy,
        usage_type: this.state.usage_type,
        ownership_type: this.state.ownership_type,
        week: this.state.week,
        points_allocation: this.state.points_allocation,
        season: this.state.season,
        usage_frequency: this.state.usage_frequency,
        owner_name: this.props.App.state.form.owner_name,
        email: this.props.App.state.form.email,
        phone_number: this.props.App.state.form.phone_number,
      }
    });
  }

  prevStep(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.props.App.setState({
      step: 1,
      form: {
        resort_name: this.props.App.state.form.resort_name,
        resort: this.props.App.state.form.resort,
        bathrooms: this.props.App.state.form.bathrooms,
        bedrooms: this.props.App.state.form.bedrooms,
        max_occupancy: this.props.App.state.form.max_occupancy,
        usage_type: this.state.usage_type,
        ownership_type: this.state.ownership_type,
        week: this.state.week,
        points_allocation: this.state.points_allocation,
        season: this.state.season,
        usage_frequency: this.state.usage_frequency,
        owner_name: this.props.App.state.form.owner_name,
        email: this.props.App.state.form.email,
        phone_number: this.props.App.state.form.phone_number,
      }
    });
  }

  next() {
    if(window.wmtw.step2.next !== undefined) {
      return window.wmtw.step2.next;
    }

    return '';
  }

  previous() {
    if(window.wmtw.step2.previous !== undefined) {
      return window.wmtw.step2.previous;
    }

    return '';
  }

  render() {
    return (
      <form className="form">
          <div className="form-group col-md-6">
            <label className="sr-only" htmlFor="week">Week</label>
            <div className="input-group">
              <span className="input-group-addon" id="week"><i className="fas fa-calendar-alt"></i></span>
              <Select2
                data={this.state.weekData}
                value={ this.state.week }
                onChange={ this.weekOnChange }
                options={
                  {
                    placeholder: 'Week',
                    minimumResultsForSearch: Infinity,
                    width: '100%'
                  }
                }
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="sr-only" htmlFor="number-points">Number of Points</label>
            <div className="input-group">
              <span className="input-group-addon"><i className="far fa-star"></i></span>
              <input type="number" className="form-control" id="number-points" placeholder="Number of Points" onChange={this.pointsAllocationOnChange} value={this.state.points_allocation} ref={this.setInputRef}/>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="sr-only" htmlFor="Usage">Usage</label>
            <div className="input-group">
              <span className="input-group-addon"><i className="fas fa-undo-alt"></i></span>
              <Select2
                data={this.state.usageData}
                value={ this.state.usage_frequency }
                onChange={ this.usageFrequencyOnChange }
                options={
                  {
                    placeholder: 'Usage',
                    minimumResultsForSearch: Infinity,
                    width: '100%'
                  }
                }
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="sr-only" htmlFor="Season">Season</label>
            <div className="input-group">
              <span className="input-group-addon"><i className="fas fa-sun"></i></span>
              <Select2
                data={this.state.seasonData}
                value={ this.state.season }
                onChange={ this.seasonOnChange }
                options={
                  {
                    placeholder: 'Season',
                    minimumResultsForSearch: Infinity,
                    width: '100%'
                  }
                }
              />
            </div>
          </div>
          <div className="col-md-6 col-md-push-6">
            <button type="button" className="btn btn-lg btn-block btn-primary" onClick={ this.nextStep.bind(this) }>{ this.next() } <i className="fas fa-chevron-circle-right fa-pull-right fa-lg"></i></button>
          </div>
          <div className="col-md-6 col-md-pull-6">
            <button type="button" className="btn btn-lg btn-block btn-secondary" onClick={ this.prevStep.bind(this) }><i className="fas fa-chevron-left fa-pull-left" data-fa-transform="shrink-2 down-4"></i> { this.previous() }</button>
          </div>
      </form>
    )
  }
}

export default Step2;
