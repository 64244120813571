import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import Select2 from 'react-select2-wrapper';
import classNames  from 'classnames';

import DOMPurify from 'dompurify';

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.name}</span>
  );
}

const makeRequestCreator = () => {
  let call;
  return url => {
    if (call) {
      call.cancel("Only one request allowed at a time.");
    }
    call = axios.CancelToken.source();
    return axios.get(url, {
      cancelToken: call.token
    });
  };
};

const get = makeRequestCreator();

class Step1 extends Component {

  constructor(props) {
    super(props);

    let canNext = false;

    if(this.props.App.state.form.resort !== '') {
      canNext = true;
    }

    this.state = {
      value: this.props.App.state.form.resort_name,
      value_select: '',
      resort: this.props.App.state.form.resort,
      bathrooms: this.props.App.state.form.bathrooms,
      bedrooms: this.props.App.state.form.bedrooms,
      max_occupancy: this.props.App.state.form.max_occupancy,
      suggestions: [],
      isLoading: false,
      hasFail: false,
      resortError: false,
      canNext,
      autosuggest: false,
      isCreate: false,
      offerCount: 0,
      unitSize: [
        { id: 'Efficiency', text: 'Efficiency'},
        { id: 'Hotel Unit', text: 'Hotel Unit'},
        { id: 'Pent House', text: 'Pent House'},
        { id: 'Studio', text: 'Studio'},
        { id: 'One', text: 'One'},
        { id: 'Two', text: 'Two'},
        { id: 'Three', text: 'Three'},
        { id: 'Four', text: 'Four'},
      ],
      bathroomsData: [
        {text: '0.5', id: '0.5'},
        {text: '1', id: '1'},
        {text: '1.5', id: '1.5'},
        {text: '2', id: '2'},
        {text: '2.5', id: '2.5'},
        {text: '3', id: '3'},
        {text: '3.5', id: '3.5'},
        {text: '4', id: '4'},
        {text: '4.5', id: '4.5'},
        {text: '5', id: '5'},
      ],
      ownershipData: [
        {text: 'Deeded', id: 'Deeded'},
        {text: 'Leasehold', id: 'Leasehold'},
        {text: 'Membership', id: 'Membership'},
        {text: 'Right to Use', id: 'Right to Use'},
      ],
    };

    this.lastRequestId = null;
  };

  componentDidMount() {
    
  };

  nextStep(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if(this.state.value.length === 0 && !this.props.App.state.form.resort) {
      console.log('invalid');
      this.setState({
        resortError: true
      })
      return;
    }

    var defaultResort = false;

    if(!this.state.autosuggest && !this.props.App.state.form.resort) {
      this.props.App.clearResort();
      
      defaultResort = true;
      this.setState({
        resort: 6810
      })
      this.props.App.setState({
        resort: {
          SkyhookId: 6810,
          Name: e.target.value
        },
        offerCount: 0
      });
    }

    this.props.App.setState({
      step: 2,
      form: {
        resort_name: this.state.value,
        resort: this.state.resort,
        bedrooms: this.state.bedrooms,
        bathrooms: this.state.bathrooms,
        max_occupancy: this.state.max_occupancy,
        usage_type: this.props.App.state.form.usage_type,
        ownership_type: this.props.App.state.form.ownership_type,
        week: this.props.App.state.form.week,
        points_allocation: this.props.App.state.form.points_allocation,
        season: this.props.App.state.form.season,
        usage_frequency: this.props.App.state.form.usage_frequency,
        owner_name: this.props.App.state.form.owner_name,
        email: this.props.App.state.form.email,
        phone_number: this.props.App.state.form.phone_number,
      },
      defaultResort: defaultResort
    });
  };

  loadAutoSuggests(value) {
    if (typeof this.lastRequestId === "function") {
      this.lastRequestId();
    }

    this.setState({
      isLoading: true
    });

    this.loadAutoSuggestsRequest(value)
    .then((response) => {
      this.lastRequestId = null;
      this.setState({
        isLoading: false,
        suggestions: response.data
      });
    })
    .catch((error) => {
      this.lastRequestId = null;
      if (error.response) {
        this.setState({
          hasFail: true
        })
      }
    });
  };

  loadAutoSuggestsRequest = async value => {
    let url = `${window.wmtw.autosuggestUrl}/resort-autocomplete.php?string=${value}`;

    if(window.wmtw.settings['form-configuration'] === 'query_by') {

      let query = window.wmtw.settings.queryby.split('-');
      let type = '';
      switch(query[0]) {
          case 'Cities':
              type = 'city';
              break;
          case 'Affiliations':
              type = 'affiliation';
              break;
          case 'States':
              type = 'state';
              break;
          case 'Regions':
              type = 'region';
              break;
          default:
              break;
      }
      let string = query[1];
      url += `&s=${string}&searchType=${type}`;
    }

    try {
      return await get(url);
    } catch(err) {
      // if (axios.isCancel(err)) {
      //   console.error(`Cancelling previous request: ${err.message}`);
      // }
    }
  }



  onSuggestionSelected = (ev, data) => {
    if(this.state.isCreate !== undefined && this.state.isCreate === true) {
      return;
    }
    if(window.wmtw.autosuggest === false && ev === null) {
      this.setState({
        resort: '',
        value: '',
        value_select: '',
        canNext: false,
        autosuggest: false
      });
      this.props.App.setState({
        offerCount: 0
      });

      return;
    }

    this.setState({
      resort: window.wmtw.autosuggest ? data.suggestion.id : ev.id,
      value: window.wmtw.autosuggest ? data.suggestion.name : ev.name,
      value_select: window.wmtw.autosuggest ? undefined : ev,
      canNext: true,
      autosuggest: true
    });
    this.props.App.setState({
      offerCount: 0
    });
    this.props.App.fetchResort(window.wmtw.autosuggest ? data.suggestion.id : ev.id);
  };

  resortOnChange = (event, value) => {
    this.setState({
      resortError: false,
      value: value.newValue,
      canNext: value.newValue.length > 0,
      autosuggest: false
    });
  };

  bedroomsOnChange = (event) => {
    this.setState({
      bedrooms: event.target.value
    });
  };

  bathroomsOnChange = (event) => {
    this.setState({
      bathrooms: event.target.value
    });
  };

  weekOnChange = (event) => {
    this.setState({
      week: event.target.value
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.loadAutoSuggests(value);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  fetchResort(id) {
    let those = this;
    axios.get(window.wmtw.siteUrl+'/wp-admin/admin-ajax.php?action='+(window.wmtw.autosuggest ? 'getResort' : 'getResortId')+'&id='+id)
    .then(function (response) {
      those.props.App.setState({
        resort: response.data.data
      }, function() {
        those.fetchOfferCount();
      });
    })
    .catch(function (error) {
      console.log(error)
    });
  };

  fetchOfferCount() {
    let those = this;

    if(!this.props.App.state.defaultResort && this.props.App.state.resort.SkyhookId !== 0) {
        axios.post(window.wmtw.siteUrl+'/wp-admin/admin-ajax.php?action=offerCount&resort_id='+this.props.App.state.resort.SkyhookId)
        .then(function (response) {
            those.props.App.setState({
              offerCount: response.data
            });
            those.setState({
              canNext: true
            })
        })
        .catch(function (error) {
          console.log(error)
          those.setState({
            canNext: true
          })
        });
    } else {
      those.setState({
        canNext: true
      })
    }
  };

  setResortHadFail = (event) => {
    // console.log(event.target);
    this.setState({
      value: event.target.value,
      resort: {
        SkyhookId: 0,
        Name: event.target.value
      }
    });
    this.props.App.setState({
      resort: this.state.resort
    });
    if(event.target.value === '') {
      this.setState({
        canNext: false
      });
    } else {
      this.setState({
        canNext: true
      });
    }
  };

  showAuto() {
    const { value, suggestions, resortError } = this.state;

    // Autosuggest will pass through all these props to the input element.
    const inputProps = {
      placeholder: 'Resort Name * (Required)',
      value,
      className: classNames({'form-control': true, 'has-error': resortError}),
      onChange:this.resortOnChange
    };

    if(this.state.hasFail === false) {
      return (<Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={inputProps}
      />);
    } else {
      return (<input type="text" className={ inputProps.className } placeholder="" aria-describedby="basic-addon1" onChange={this.setResortHadFail} value={this.state.value}/>)
    }
  }

  Size = (event) => {
    this.setState({
      week: event.target.value
    });
  };

  privacy() {
    if(window.wmtw.step1.privacy !== undefined) {
      return {__html: DOMPurify.sanitize(window.wmtw.step1.privacy)};
    }

    return '';
  }

  next() {
    if(window.wmtw.step1.next !== undefined) {
      return window.wmtw.step1.next;
    }

    return '';
  }

  render() {
    return (
      <form className="form">
        <div className="form-group col-md-6">
          <label className="sr-only" htmlFor="resort">Resort</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fas fa-home"></i></span>
            { this.showAuto() }
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="sr-only" htmlFor="unit-size">Unit Size</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fas fa-bed"></i></span>
            <Select2
                data={this.state.unitSize}
                value={ this.state.bedrooms }
                onChange={ this.bedroomsOnChange }
                options={
                  {
                    placeholder: 'Unit Size',
                    minimumResultsForSearch: Infinity,
                    width: '100%'
                  }
                }
              />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="sr-only" htmlFor="bathrooms"># Bathrooms</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fas fa-shower"></i></span>
            <Select2
                data={this.state.bathroomsData}
                value={ this.state.bathrooms }
                onChange={ this.bathroomsOnChange }
                options={
                  {
                    placeholder: '# Bathrooms',
                    minimumResultsForSearch: Infinity,
                    width: '100%'
                  }
                }
              />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="sr-only" htmlFor="ownership">Ownership</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fas fa-key"></i></span>
            <Select2
                data={this.state.ownershipData}
                value={ this.state.week }
                onChange={ this.weekOnChange }
                options={
                  {
                    placeholder: 'Ownership',
                    minimumResultsForSearch: Infinity,
                    width: '100%'
                  }
                }
              />
          </div>
        </div>
        <div className="col-md-6 col-md-push-6">
          <button type="button" className={classNames({"btn btn-lg btn-block btn-primary": true, 'disabled': !this.state.canNext}) } onClick={ this.nextStep.bind(this) } >{this.next()} <i className="fas fa-chevron-circle-right fa-pull-right fa-lg"></i></button>
        </div>
        <div className="col-md-6 col-md-pull-6">
          <small className="text-muted" dangerouslySetInnerHTML={this.privacy()}></small>
        </div>
      </form>
    );
  }

}

export default Step1;
