import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';

import DOMPurify from 'dompurify';

class Step4 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      resort: false,
      isLoading: false,
      offerCount: 0
    }

    window.sessionStorage.removeItem('state');

  }

  componentWillMount() {
    // window.history.pushState({}, "", '/confirmation/owner/value/'+window.page_base+'/') ;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.offline === true && nextProps.offline === false) {
      window.history.pushState({}, "", '/thank-you/');
    }

    return true;
  }

  prevStep(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.props.App.setState({
      step: 3,
      form: {
        resort_name: this.props.App.state.form.resort_name,
        resort: this.props.App.state.form.resort,
        bathrooms: this.props.App.state.form.bedrooms,
        bedrooms: this.props.App.state.form.bathrooms,
        max_occupancy: this.props.App.state.form.max_occupancy,
        usage_type: this.props.App.state.form.usage_type,
        ownership_type: this.props.App.state.form.ownership_type,
        week: this.props.App.state.form.week,
        points_allocation: this.props.App.state.form.points_allocation,
        season: this.props.App.state.form.season,
        usage_frequency: this.props.App.state.form.usage_frequency,
        owner_name: this.props.App.state.form.owner_name,
        email: this.props.App.state.form.email,
        phone_number: this.props.App.state.form.phone_number,
      }
    });
  }

  urlExists(url)
  {
    return true;
    // axios.head(url, {
    //   cors: true,
    // }).then(response => {
    //   return response.status!==404;
    // }).catch(error => {
    //   return false;
    // });
  }

  propertyImage() {
    const url = window.wmtw.imagePath+this.props.App.state.resort.SkyhookId+'r1.jpg';
    if(this.urlExists(url)) {
      return url;
    } else {
      return window.wmtw.imagePath+'6810r1.jpg';
    }
  }

  property() {
    return (
      <div className="ml-1">
        <h3><i className="fas fa-info-circle"></i> 
        { _.has(this.props.App.state.resort, 'Name') ? 
          ' '+this.props.App.state.resort.Name
         : (
          ' Property Name'
        )}
        </h3>
        { _.has(this.props.App.state.resort, 'SkyhookId') &&
          <img src={this.propertyImage()} alt="" className="property-img"/>
        }
        <h5><em>{ this.propertyContent() }</em></h5>
        { _.has(this.props.App.state.resort, 'Address') && _.has(this.props.App.state.resort, 'Location') &&
        <div className="details">
          { this.props.App.state.resort.Address },<br/>
          { this.props.App.state.resort.Location.City.Name }, { this.props.App.state.resort.Location.Country.Name }
        </div>
        }
      </div>
    );
  }

  propertyContent() {
    if(window.wmtw.step2['block-1'] !== undefined) {
      return window.wmtw.step2['block-1'];
    }

    return '';
  }

  confirmation() {
    if(window.wmtw.confirmation.confirmation !== undefined) {
      return {__html: DOMPurify.sanitize(window.wmtw.confirmation.confirmation)};
    }

    return '';
  }

  render() {
    return(
      <div className="container below-hero">
        <div className="row">
            <div className="main-text col-md-12">
                <div className="col-md-6 col-md-push-4" dangerouslySetInnerHTML={this.confirmation()}></div>
                <div className="col-md-4 col-md-pull-6 info-box">
                    { this.property() }
                    <h4>
                        <i className="fas fa-arrow-alt-circle-right">
                        </i> Your timeshare offer
                    </h4>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

export default Step4;
