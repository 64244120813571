import React, { Component } from 'react';
import classNames  from 'classnames';

class StepHeader extends Component {

  title_1() {
    if(window.title_1 !== undefined) {
      return window.title_1;
    }

    return '';
  }

  title_2() {
    if(window.title_2 !== undefined) {
      return window.title_2;
    }

    return '';
  }

  title_3() {
    if(window.title_3 !== undefined) {
      return window.title_3;
    }

    return '';
  }

  title_4() {
    if(window.title_4 !== undefined) {
      return window.title_4;
    }

    return '';
  }

  render(){
    return (
      <div className="col-md-8 col-md-push-2 steps-block" style={{marginTop: 0}}>
          <div className="col-xs-3">
              <span className={ classNames({ "step": true, 'active': this.props.App.state.step === 1}) }>
                  1
              </span>
              <p className="text-center">
                  <strong>
                      Resort
                  </strong>
              </p>
          </div>
          <div className="col-xs-3">
              <span className={ classNames({ "step": true, 'active': this.props.App.state.step === 2}) }>
                  2
              </span>
              <p className="text-center">
                  Ownership
              </p>
          </div>
          <div className="col-xs-3">
              <span className={ classNames({ "step": true, 'active': this.props.App.state.step === 3}) }>
                  3
              </span>
              <p className="text-center">
                  Details
              </p>
          </div>
          <div className="col-xs-3">
              <span className={ classNames({ "step": true, 'active': this.props.App.state.step === 4}) }>
                  4
              </span>
              <p className="text-center">
                  Offer!
              </p>
          </div>
      </div>
    );
  }
}

export default StepHeader;