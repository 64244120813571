import React, { Component } from 'react';

import Step1 from './Step1';
import Step2 from './Step2';
import StepHeader from './StepHeader';
import classNames  from 'classnames';
import _ from 'lodash';
import axios from 'axios';

import DOMPurify from 'dompurify';

class StepList extends Component {

  componentDidMount() {
    var those = this;
    those.refreshSizes();
    
  };

  componentDidUpdate() {
    var those = this;
    those.refreshSizes();
  };

  refreshSizes() {
    window.$(document).ready(function($) {
      $('.collapse').collapse();
      $('.carousel').carousel();

      $('.carousel').each(function() {
        var height = 0;

        $(this).find("item").removeClass('loaded');

        $(this).find('.item .form-testimonial').each(function() {
          if($(this).height() > height) {
            height = $(this).height();
          }
          $(this).parent().addClass("loaded");
        })
        $(this).find('.item .form-testimonial').css({height: height});
      });
    })
  }

  phoneNumber() {
    if(window.phone !== undefined) {
      return 'tel:'+window.phone;
    }

    return '#';
  }

  phoneNumberString() {
    if(window.phone !== undefined) {
      return window.phone;
    }

    return '';
  }

  background() {
    if(window.wmtw.settings['hero-image'] !== undefined) {
      return 'url('+window.wmtw.settings['hero-image']+')';
    }

    return '';
  }

  title() {
    if(window.wmtw.step1.title !== undefined) {
      return window.wmtw.step1.title;
    }

    return '';
  }

  subtitle() {
    if(window.wmtw.step1.subtitle !== undefined) {
      return window.wmtw.step1.subtitle;
    }

    return '';
  }

  subheading() {

    var subheading = '';
    var previousText = '';
    var linkString = '';

    switch(this.props.App.state.step) {
      case 1:
        subheading = window.wmtw.step1.subheading !== undefined ? window.wmtw.step1.subheading : '';
        if(subheading.indexOf('[next]') === -1) {
          break;
        }
        previousText = subheading.substring(
          0,
          subheading.lastIndexOf("[next]")
        );
        linkString = subheading.substring(
          subheading.lastIndexOf("[next]") + 6,
          subheading.lastIndexOf("[/next]")
        );

        return (<span>
          { previousText } <button type="button" className="btn-link" onClick={ this.skipToStepThree.bind(this) } >{ linkString }</button>
        </span>);
      case 2:
        subheading = window.wmtw.step2.subheading !== undefined ? window.wmtw.step2.subheading : '';
        if(subheading.indexOf('[next]') === -1) {
          break;
        }
        previousText = subheading.substring(
          0,
          subheading.lastIndexOf("[next]")
        );
        linkString = subheading.substring(
          subheading.lastIndexOf("[next]") + 6,
          subheading.lastIndexOf("[/next]")
        );

        return (<span>
          { previousText } <button type="button" className="btn-link skip-step-2" onClick={ this.skipToStepThree.bind(this) } >{ linkString } <i className="fas fa-angle-double-right"></i></button>
        </span>);
      default:
        break;
    }

    return (<span> { subheading } </span>);
  }

  skipToStepTwo() {
    this.skip(2);
  }

  skipToStepThree() {
    this.skip(3);
  }

  skip(step) {

    this.props.App.setState({
      step: step,
      form: {
        resort_name: this.props.App.state.form.resort_name,
        resort: this.props.App.state.form.resort,
        bathrooms: this.props.App.state.form.bedrooms,
        bedrooms: this.props.App.state.form.bathrooms,
        max_occupancy: this.props.App.state.form.max_occupancy,
        usage_type: this.props.App.state.form.usage_type,
        ownership_type: this.props.App.state.form.ownership_type,
        week: this.props.App.state.form.week,
        points_allocation: this.props.App.state.form.points_allocation,
        season: this.props.App.state.form.season,
        usage_frequency: this.props.App.state.form.usage_frequency,
        owner_name: this.props.App.state.form.owner_name,
        email: this.props.App.state.form.email,
        phone_number: this.props.App.state.form.phone_number,
      }
    });
  }

  snippet() {
    if(window.wmtw.settings.snippet !== undefined) {
      return {__html: DOMPurify.sanitize(window.wmtw.settings.snippet)};
    }

    return '';
  }

  image_snippet() {
    if(window.wmtw.settings.image_snippet !== undefined) {
      return window.wmtw.settings.image_snippet;
    }

    return false;
  }

  phone_cta() {
    if(window.wmtw.settings.phone_cta !== undefined) {
      return window.wmtw.settings.phone_cta;
    }

    return '';
  }

  phone() {
    if(window.wmtw.settings.phone !== undefined) {
      return window.wmtw.settings.phone;
    }

    return '';
  }

  show_steps() {
    if(window.wmtw.settings.show_steps !== undefined) {
      return window.wmtw.settings.show_steps;
    }

    return true;
  }

  step1() {
    if(this.props.App.state.step === 1) {
      return <Step1 App={ this.props.App } />;
    }
  }

  step2() {
    if(this.props.App.state.step === 2) {
      return <Step2 App={ this.props.App } />;
    }
  }

  testimonials(step) {
    if(this.props.App.state.step !== step) {
      return;
    }

    switch(step) {
      case 1:
        if(window.wmtw.step1['content-2'] === 'cpt') {
          return this.testimonailsHtml(window.wmtw.testimonials, step);
        } else {
          return this.testimonailsHtml(window.wmtw.step1['content-2-repeater'], step);
        }
      case 2:
        if(window.wmtw.step2['content-2'] === 'cpt') {
          return this.testimonailsHtml(window.wmtw.testimonials, step);
        } else {
          return this.testimonailsHtml(window.wmtw.step2['content-2-repeater'], step);
        }
      default:
      break;
    }
  }

  testimonailsHtml(data, step) {
    return (
      <div className="testimonials">
        <i className="fas fa-quote-left"></i>
        <div className="carousel slide" data-ride="carousel" id={'testimonialCarousel'+step}>
          <ol className="carousel-indicators">
            {
              data.map(function(name, index){
                return (
                  <li key={ index } className={classNames({'active': index === 0})} data-slide-to={ index } data-target={'#testimonialCarousel'+step}></li>
                );
              })
            }
          </ol>
          <div className="carousel-inner">
            {
              data.map(function(name, index){
                return (<div key={ index } className={ classNames({ 'item': true, 'active': index === 0 }) }>
                  <p className="form-testimonial">
                      <em>{ name.text }</em>
                      <br />
                      <strong>{ name.title }</strong>
                  </p>
                </div>);
              })
            }
          </div>
        </div>
      </div>
    );
  }

  faq(step) {
    if(this.props.App.state.step !== step) {
      return;
    }

    switch(step) {
      case 1:
        if(window.wmtw.step1['content-1'] === 'cpt') {
          return this.faqHtml(window.wmtw.faqs, window.wmtw.step1['content-1-ext']);
        } else {
          return this.faqHtml(window.wmtw.step1['content-1-repeater'], window.wmtw.step1['content-1-ext']);
        }
      default:
      break;
    }
  }

  faqHtml(data, text) {
    return(
      <div className="feature-card ml-1">
        <h3 className="text-center">
            <i className="fas fa-question-circle"></i> Questions
        </h3>
        <h5>
            <em>{ text } </em>
        </h5>
        <div aria-multiselectable="false" className="panel-group" id="accordion" role="tablist">
            {
              data.map(function(name, index){
                return (<div key={index} className="panel panel-default">
                  <div className="panel-heading" id={ 'heading'+index } role="tab">
                      <h4 className="panel-title">
                          <a aria-controls={ 'collapse'+ index } aria-expanded="false" className="collapsed" data-parent="#accordion" data-toggle="collapse" href={ '#collapse'+ index } role="button">
                              { name.title } <i className="fas fa-caret-right"></i> <i className="fas fa-caret-down"></i>
                          </a>
                      </h4>
                  </div>
                  <div aria-labelledby={ 'heading'+index } className="panel-collapse collapse" data-parent="#accordion" id={ 'collapse'+ index } role="tabpanel">
                      <div className="panel-body">{ name.text }</div>
                  </div>
                </div>);
              })
            }
        </div>
    </div>
    )
  }

  urlExists(url)
  {
      return true;

      // axios.head(url, {
      //   cors: true,
      // }).then(response => {
      //   return response.status!==404;
      // }).catch(error => {
      //   return false;
      // });
  }

  propertyImage() {
    const url = window.wmtw.imagePath+this.props.App.state.resort.SkyhookId+'r1.jpg';
    if(this.urlExists(url)) {
      return url;
    } else {
      return window.wmtw.imagePath+'6810r1.jpg';
    }
  }

  property(step) {
    if(this.props.App.state.step !== step) {
      return;
    }

    return (
      <div className="feature-card property-card ml-1">
        <h3><i className="fas fa-info-circle"></i> 
        { _.has(this.props.App.state.resort, 'Name') ? 
          ' '+this.props.App.state.resort.Name
         : (
          ' Property Name'
        )}
        </h3>
        { _.has(this.props.App.state.resort, 'SkyhookId') &&
          <img src={this.propertyImage()} alt="" className="property-img"/>
        }
        <h5><em>{ this.propertyContent() }</em></h5>
      </div>
    );
  }

  propertyContent() {
    if(window.wmtw.step2['block-1'] !== undefined) {
      return window.wmtw.step2['block-1'];
    }

    return '';
  }

  render(){
    return (
      <div className="main index3" style={{ backgroundImage: this.background() }}>
        <div className="container">
          <div className="row">
            <div id="lead-form" className="form-header col-md-12">
              <h1 className="text-center">{ this.title() }</h1>
              <h2 className="text-center">{ this.subtitle() }</h2>
            </div>
            <div className="col-md-8 input-form">
              { this.show_steps() &&
                <StepHeader App={ this.props.App }/>
              }
              <div className="col-md-12 check-headline">
                <h4 className="text-center">{ this.subheading() }</h4>
              </div>
        
              { this.step1() }
              { this.step2() }
              
              <div className="col-md-12 contact-band">
                <div className="col-md-3 col-xs-6" dangerouslySetInnerHTML={this.snippet()}></div>
                <div className="col-md-4 col-xs-6">
                  { this.image_snippet() &&
                    <img src={ this.image_snippet() } alt=""/>
                  }
                </div>
                <div className="col-md-5">
                  <h4>{ this.phone_cta() } <br/><a href={'tel:'+ this.phone() }>{ this.phone() }</a></h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              { this.property(2) }
              { this.testimonials(2) }
              { this.faq(1) }
              { this.testimonials(1) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StepList;